@import "../variables";

.hn {
    &__inbox {
        &--search-type-item {
            border-radius: 6px;
            border: 1px solid #eaeaea;
            padding: 4px;
            cursor: pointer;

            &-active {
                background-color: #0e9aef;
                border-radius: 6px;
                color: white;
            }

            &:hover {
                background-color: #0e9aef;
                border-radius: 6px;
                color: white;
            }
        }

        &--right {
            padding-top: 20px;
            border-right: 1px solid;
            border-color: rgba($color-gray, 0.4);
            height: $full-screen-vh;
        }

        &--center {
            position: relative;
            overflow: hidden;
            //height: calc(100% - 70px);
            &-header {
                padding-top: 20px;
            }

            &-content {
                height: calc(100vh - 360px);
                overflow-y: scroll;
            }

            &-comment {
                //height: 40px;
            }
        }

        &--left {
            padding-top: 20px;
            border-left: 1px solid;
            border-color: rgba($color-gray, 0.4);
            height: $full-screen-vh;
        }

        &--item-comment-left {
            margin-bottom: 10px;
        }

        &--item-friend {
            padding: 0 15px;

            &:hover {
                background-color: rgba($color-gray, 0.1);
                cursor: pointer;
            }

            &-active {
                background-color: rgba($color-primary-blur, 0.05);
            }
        }

        &--left-header {
            position: relative;

            &-action {
                position: absolute;
                right: 0;
                top: 0;
                margin-top: 25px;
                margin-right: 15px;
            }
        }

        &--no-seleted {
            padding-top: 200px;
        }

        &--profile-contact-tab {
            display: inline-block;
            cursor: pointer;
            border: 1px solid #e6e6e6;
            border-radius: 6px;
            margin-right: 6px;

            &:hover {
                background-color: #00a1c9;
                color: white
            }

            &-active {
                background-color: #00a1c9;
                color: white
            }
        }

        &--content {
            overflow-y: scroll;
            height: calc(100vh - 350px);
        }

        &--friend-wrapper{
            height: calc(100vh - 250px);
            overflow-y: scroll;
        }
    }
}

@include screen-small {
    .hn {
        &__inbox {

        }
    }
}

@include screen-medium {
    .hn {
        &__inbox {

        }
    }

}

@include screen-lg {

}

.chat {
    //width: calc(65% - 85px);
}

.chat .messages-chat {
    //padding: 25px 35px;
}

.chat .messages-chat .message {
    display:flex;
    align-items: center;
}

.chat .messages-chat .text {
    //margin: 0 35px;
    background-color: #f6f6f6;
    padding: 10px 15px;
    border-radius: 12px;
    margin-bottom: 10px;
}

.text-only {
    margin-left: 45px;
}

.time {
    font-size: 10px;
    color:lightgrey;
    margin-bottom:10px;
    //margin-left: 85px;
}

.response-time {
    float: right;
    //margin-right: 40px !important;
}

.response {
    float: right;
    margin-right: 0px !important;
    margin-left:auto; /* flexbox alignment rule */
}

.response .text {
    background-color: #e3effd !important;
}
