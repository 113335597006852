@import "../variables";

.ant-select-item-option-content {
    color: black
}

.ant-select-selection-item {
    color: black
}

.hn {
    &__plans-pricing {
        &__tabs-state {
            width: 1250px;
            margin: auto;
            height: 60px;
            overflow-x: scroll;
            white-space: nowrap;
        }

        &__segment {
            .ant-radio-group {
                background-color: white;
                padding: 3px;
                border-radius: 15px;
                -webkit-box-shadow: 1px 1px 9px -4px #6a6a6a;
                box-shadow: 1px 1px 9px -4px #6a6a6a;
            }

            .ant-radio-button-wrapper {
                border: none;
                border-radius: 15px;
                width: 145px
            }

            .ant-radio-button-wrapper:not(:first-child)::before {
                width: 0;
                border-radius: 15px;
            }

            .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                background-color: $color-primary-blur;
                border: none;
                border-radius: 15px;
            }
        }

        &__packages {
            &--item {
                //background-color: rgba($color-primary-blur, 0.1);
                background-color: white;
                border-radius: 15px;
                padding: 20px;
                -moz-box-shadow: 1px 1px 9px -4px #6a6a6a;
                -webkit-box-shadow: 1px 1px 9px -4px #6a6a6a;
                -o-box-shadow: 1px 1px 9px -4px #6a6a6a;
                box-shadow: 1px 1px 9px -4px #6a6a6a;

                .list-style-none {
                    p {
                        color: $color-primary;
                    }

                    //i {
                    //    color: $color-primary-blur;
                    //}

                }

                &-premium {
                    //background-color: $color-primary !important;

                    //.plans-pricing__packages--title-content {
                    //    p {
                    //        color: white !important;
                    //    }
                    //}

                    .plans-pricing__packages--title-price {
                        background-color: $color-primary-blur;
                    }

                    .list-style-none {
                        i {
                            color: $color-primary-blur !important;
                        }

                        //p {
                        //    color: white !important;
                        //}
                    }
                }
            }

            &--title {
                &-content {
                    padding: 10px;
                }

                &-price {
                    padding: 10px;
                    background-color: $color-primary-blur;
                    border-radius: 10px;

                    p {
                        color: white;
                    }
                }
            }

            &--select {
                .ant-select-clear {
                    background-color: transparent !important;
                }

                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    border-radius: 6px;
                    background-color: transparent;
                    padding: 6px;
                }

                .ant-select-selection-item {
                    background-color: transparent;
                    border-color: $color-primary-blur;
                    color: $color-primary-blur;
                    border-radius: 7px;
                }

                .anticon-close {
                    svg {
                        margin-bottom: 4px;
                    }
                }
            }

            &--submit {
                background-color: rgba($color-primary-blur, 0.8);
                font-size: 15px;
                border-radius: 20px;
                text-align: center;
                color: white;
                padding: 6px 0;
                cursor: pointer;
            }
        }
    }
}

@include screen-small {
    .hn {
        &__plans-pricing {
            &__packages {
                &--title {
                    .fs--24px {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}

@include screen-medium {

}

@include screen-lg {

}
