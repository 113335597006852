.AutomationCanvas {
    /*--edges-stroke-color: green;*/
    width: 90vw;
    height: calc(90vh - 130px);
    /*height: 90vh;*/
    background-color: var(--color-base100);

}

.react-flow__edge-path {
    stroke: transparent;
}

.react-flow__edge-path, .react-flow__connection-path {
    stroke: #af9ede !important;
    stroke-width: 2 !important;
}

.Controls {
    top: 10px;
    bottom: auto;
}

/* export const AutomationCanvas = css`
  --edges-stroke-color: green;

  width: 100vw;
  height: 100vh;
  background-color: var(--color-base100);

  .react-flow__edge-path {
    stroke: var(--edges-stroke-color);
  }
`;

export const Controls = css`
  top: 10px;
  bottom: auto;
`; */
