@import "./pages/login";
@import "./pages/membership-plans";
@import "./pages/my-subscription";
@import "./pages/plansPricing";
@import "./pages/inbox";
@import "./pages/contact";
@import "./pages/task";
@import "./pages/workflow";
@import "./pages/embedded";
@import "./header";
@import "./layout";
@import "variables";
@import "antdOveride";
@import "~formiojs/dist/formio.full.min.css";

.set {
    &--height-page {
        height: calc(100vh - 120px);
    }
}

.icon-ant {
    svg {
        margin-top: -5px;
    }
}

.ck-editor__editable_inline {
    min-height: 300px;
}

.ck-sticky-panel__content_sticky {
    margin-top: 80px !important;
}

.cursorPointer {
    cursor: pointer;
}

.custom {
    &--modal-confirm {
        .ant-modal-confirm-btns {
            width: 100%;
            text-align: center;
        }
    }

    &--modal-confirm-ok {
        padding: 2px 30px !important;
    }
}

.vertical {
    &--middle {
        vertical-align: middle !important;
    }
}

.color {
    &--main {
        color: $color-primary !important;
    }

    &--primary {
        color: $color-primary-blur !important;
    }

    &--white {
        color: white !important
    }

    &--black {
        color: black
    }

    &--description {
        color: rgba(0, 0, 0, 0.45)
    }

    &--foreign {
        color: $color-foreign;
    }

    &--red {
        color: red;
    }

    &--gray {
        color: $color-gray
    }

    &--disabled {
        color: #919191;
    }
}

.border-color {
    &--primary {
        border-color: $color-primary-blur !important;
    }

    &--white {
        border-color: white !important;
    }

    &--black {
        border-color: black
    }

    &--foreign {
        border-color: $color-foreign;
    }
}

.bg {
    &--primary {
        background-color: $color-primary-blur !important;
    }

    &--white {
        background-color: white
    }

    &--black {
        background-color: black
    }

    &--foreign {
        background-color: $color-foreign;
    }

    &--disabled {
        background-color: #eaeaea;
    }

    &--content {
        background-color: #F7F7F7;
    }
}

.btn-bg {
    &--disabled {
        border-color: unset !important;
        background-color: #C4C4C4 !important;
        box-shadow: unset !important;
        color: #FFFFFF;

        &:hover {
            border-color: unset !important;
            background-color: rgba(#C4C4C4, 0.6) !important;
            box-shadow: unset !important;
        }

        &:active {
            border-color: unset !important;
            background-color: rgba(#C4C4C4, 0.6) !important;
            box-shadow: unset !important;
        }

        &:focus {
            border-color: unset !important;
            background-color: rgba(#C4C4C4, 0.6) !important;
            box-shadow: unset !important;
        }
    }

    &--white {
        border-color: unset;
        background-color: white;

        &:hover {
            border-color: unset !important;
            background-color: rgba(white, 0.6) !important;
        }
    }

    &--black {
        border-color: unset;
        background-color: black;

        &:hover {
            border-color: unset !important;
            background-color: rgba(black, 0.6) !important;
        }
    }

    &--foreign {
        border-color: unset;
        background-color: $color-foreign;
        box-shadow: unset !important;

        &:hover {
            border-color: unset !important;
            background-color: rgba($color-foreign, 0.6) !important;
            box-shadow: unset !important;
        }

        &:active {
            border-color: unset !important;
            background-color: rgba($color-foreign, 0.6) !important;
            box-shadow: unset !important;
        }

        &:focus {
            border-color: unset !important;
            background-color: rgba($color-foreign, 0.6) !important;
            box-shadow: unset !important;
        }
    }

    &--primary {
        border-color: unset;
        background-color: $color-primary-blur;
        box-shadow: unset !important;

        &:hover {
            border-color: unset !important;
            background-color: rgba($color-primary-blur, 0.6) !important;
            box-shadow: unset !important;
        }

        &:active {
            border-color: unset !important;
            background-color: rgba($color-primary-blur, 0.6) !important;
            box-shadow: unset !important;
        }

        &:focus {
            border-color: unset !important;
            background-color: rgba($color-primary-blur, 0.6) !important;
            box-shadow: unset !important;
        }
    }
}

.mb {
    //&--14px{
    //    font-size: 14px !important;
    //}
    //&--12px{
    //    font-size: 12px !important;
    //}
    @for $f from 1 through 40 {
        &--s#{$f}px {
            margin-bottom: -#{$f}px !important;
        }
    }
    @for $f from 1 through 40 {
        &--#{$f}px {
            margin-bottom: #{$f}px !important;
        }
    }
}

.mt {
    //&--14px{
    //    font-size: 14px !important;
    //}
    //&--12px{
    //    font-size: 12px !important;
    //}
    @for $f from 1 through 40 {
        &--s#{$f}px {
            margin-top: -#{$f}px !important;
        }
    }
    @for $f from 1 through 100 {
        &--#{$f} {
            margin-top: #{$f}% !important;
        }
    }
}

.button-color {
    &--transparent {
        label {
            color: transparent !important;
        }
    }
}

.p {
    &-t {
        @for $w from 1 through 500 {
            &--#{$w}px {
                padding-top: #{$w}px !important;
            }
        }
    }

    &-x {
        @for $w from 1 through 500 {
            &--#{$w}px {
                padding-left: #{$w}px !important;
                padding-right: #{$w}px !important;
            }
        }
    }

    &-y {
        @for $w from 1 through 500 {
            &--#{$w}px {
                padding-top: #{$w}px !important;
                padding-bottom: #{$w}px !important;
            }
        }
    }
}

.pt {
    //&--14px{
    //    font-size: 14px !important;
    //}
    //&--12px{
    //    font-size: 12px !important;
    //}
    @for $f from 1 through 100 {
        &--s#{$f}px {
            padding-top: -#{$f}px !important;
        }
    }
    @for $f from 1 through 100 {
        &--#{$f} {
            padding-top: #{$f}% !important;
        }
    }
}

.m {
    &-l {
        @for $w from 1 through 500 {
            &--#{$w}px {
                margin-left: #{$w}px !important;
            }
        }
        @for $w from 1 through 500 {
            &--s#{$w}px {
                margin-left: -#{$w}px !important;
            }
        }
        @for $w from 1 through 100 {
            &-#{$w} {
                margin-left: #{$w}% !important;
            }
        }
    }

    &-t {
        @for $w from 1 through 500 {
            &--#{$w}px {
                margin-top: #{$w}px !important;
            }
        }
    }

    &-b {
        @for $w from 1 through 500 {
            &--#{$w}px {
                margin-bottom: #{$w}px !important;
            }
        }
    }

    &-x {
        @for $w from 1 through 500 {
            &--#{$w}px {
                margin-left: #{$w}px !important;
                margin-right: #{$w}px !important;
            }
        }
    }

}

.width {
    @for $w from 1 through 1000 {
        &-#{$w}px {
            width: #{$w}px !important;
        }
    }

    @for $w from 1 through 1000 {
        &-#{$w}rem {
            width: #{$w}rem !important;
        }
    }

    @for $w from 1 through 100 {
        &-#{$w} {
            width: #{$w}% !important;
        }
    }
    //&-90{
    //    width: 90% !important;
    //}
}

.fw {
    @for $w from 1 through 1000 {
        &--#{$w} {
            font-weight: #{$w} !important;
        }
    }

}

.border {
    &__radius {
        @for $w from 1 through 20 {
            &--#{$w}px {
                border-radius: #{$w}px !important;
            }
        }
        @for $w from 1 through 100 {
            &--#{$w} {
                border-radius: #{$w}% !important;
            }
        }
    }
}

.max-width {
    @for $w from 1 through 2000 {
        &--#{$w}px {
            max-width: #{$w}px !important;
        }
    }

    @for $w from 1 through 2000 {
        &--#{$w}rem {
            width: #{$w}rem !important;
        }
    }

    @for $w from 1 through 100 {
        &--#{$w} {
            width: #{$w}% !important;
        }
    }
    //&-90{
    //    width: 90% !important;
    //}
}

.max-height {
    @for $w from 1 through 2000 {
        &--#{$w}px {
            max-height: #{$w}px !important;
        }
    }
}

.min-height {
    @for $w from 1 through 2000 {
        &--#{$w}px {
            min-height: #{$w}px !important;
        }
    }
}

.height {
    @for $w from 1 through 500 {
        &--#{$w}px {
            height: #{$w}px !important;
        }
    }

    @for $w from 1 through 500 {
        &--#{$w}rem {
            height: #{$w}rem !important;
        }
    }

    @for $w from 1 through 100 {
        &--#{$w} {
            height: #{$w}% !important;
        }
    }

    @for $w from 1 through 100 {
        &--#{$w}vw {
            height: #{$w}vw !important;
        }
    }
    //&-90{
    //    width: 90% !important;
    //}
}

.left {
    @for $w from 1 through 500 {
        &--#{$w}px {
            left: #{$w}px !important;
        }
    }
    @for $w from 1 through 100 {
        &-#{$w} {
            left: #{$w}% !important;
        }
    }

    &-s {
        @for $w from 1 through 500 {
            &--#{$w}px {
                left: -#{$w}px !important;
            }
        }
    }
}

.right {
    @for $w from 1 through 500 {
        &--#{$w}px {
            right: #{$w}px !important;
        }
    }
    @for $w from 1 through 100 {
        &-#{$w} {
            right: #{$w}% !important;
        }
    }

    &-s {
        @for $w from 1 through 500 {
            &--#{$w}px {
                right: -#{$w}px !important;
            }
        }
    }
}

.fs {
    @for $f from 1 through 1000 {
        &--#{$f} {
            font-size: #{$f}% !important;
        }
    }
    @for $f from 1 through 100 {
        &--#{$f}px {
            font-size: #{$f}px !important;
        }
    }

    @for $f from 1 through 100 {
        &--#{$f}rem {
            font-size: #{$f}rem !important;
        }
    }
}

.fs-btn-antd {
    span {
        @for $f from 1 through 100 {
            &--#{$f}px {
                font-size: #{$f}px !important;
            }
        }
        @for $f from 1 through 100 {
            &--#{$f}rem {
                font-size: #{$f}rem !important;
            }
        }
    }
}

.cursorPointer {
    cursor: pointer;
}

.object-fit {
    object-fit: cover;
}

.slide-dot-inside {
    .slick-prev {
        display: none !important;
    }

    .slick-next {
        display: none !important;
    }

    .slick-dots {
        bottom: 0;
        margin-bottom: 15px;

        li {
            button {
                &:before {
                    font-size: 15px;
                    color: $color-foreign
                }
            }
        }
    }
}

body {
    width: 100% !important;
    background-color: white;
    //background-color: #F7F7F7;
    //overflow: hidden;
}

.filter-component {
    .ant-form-item {
        margin-bottom: 8px;
    }
}

.box--shadow {
    -webkit-box-shadow: 1px 1px 9px -4px #000000;
    box-shadow: 1px 1px 9px -4px #000000;
}

.pre-wrap {
    white-space: pre-wrap;
}

.verify {
    &__form {
        &-input {
            &--error {
                .ant-input-affix-wrapper {
                    border-color: red;
                }
            }

            &--warning {
                .ant-input-affix-wrapper {
                    border-color: $color-foreign;
                }
            }
        }

        &-message {
            &--error {
                .ant-form-item-explain {
                    div {
                        color: red;
                        margin-top: 3px;
                    }
                }
            }

            &--warning {
                .ant-form-item-explain {
                    div {
                        color: $color-foreign;
                        margin-top: 3px;
                    }
                }
            }
        }
    }
}

@include screen-small {

}

@include screen-medium {
    .width__lg {
        @for $w from 1 through 500 {
            &--#{$w}px {
                width: #{$w}px !important;
            }
        }

        @for $w from 1 through 500 {
            &--#{$w}rem {
                width: #{$w}rem !important;
            }
        }

        @for $w from 1 through 100 {
            &--#{$w} {
                width: #{$w}% !important;
            }
        }
        //&-90{
        //    width: 90% !important;
        //}
    }
}

@include screen-lg {
    .width__lg {
        @for $w from 1 through 500 {
            &--#{$w}px {
                width: #{$w}px !important;
            }
        }

        @for $w from 1 through 500 {
            &--#{$w}rem {
                width: #{$w}rem !important;
            }
        }

        @for $w from 1 through 100 {
            &--#{$w} {
                width: #{$w}% !important;
            }
        }
        //&-90{
        //    width: 90% !important;
        //}
    }
}

@include screen-max-lg {

}
