@import "../variables";

.hn {
    &__memberships-plans {
        //position: relative;
        //& body {
        //    overflow: hidden;
        //}

        &--quotation {

            &-table {
                .ant-btn-background-ghost.ant-btn-primary {
                    color: $color-primary-blur;
                    border-color: $color-primary-blur;
                    padding: 0px 40px;
                }
            }
        }

        &--tag-recommended {
            position: absolute;
            top: 0;
            right: 0;
            background-color: white;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            padding: 5px 10px;
            font-weight: bold;
            font-size: 12px;
            margin-top: -30px;
            margin-right: 27%;
        }

        &--feature {
            &-item-content {
                max-height: 0;
                transition: max-height 0.35s ease-out;
                overflow: hidden;

                &-active {
                    max-height: 5000px;
                    transition: max-height 0.35s ease-in;
                }
            }
        }

        &--plan-item {
            background-color: #F1F9FB;
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            margin-bottom: 25px;
        }

        &--contact {
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            margin-bottom: 25px;
        }

    }
}

@-webkit-keyframes pulse-grow-on-hover {
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

@keyframes pulse-grow-on-hover {
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.pulse-grow-on-hover {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.pulse-grow-on-hover:hover, .pulse-grow-on-hover:focus, .pulse-grow-on-hover:active {
    -webkit-animation-name: pulse-grow-on-hover;
    animation-name: pulse-grow-on-hover;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

body:has(.hn__memberships-plans) {
    overflow: hidden;
}


@include screen-small {
    .hn__memberships-plans {
        &--quotation-item {
            margin-bottom: 25px;
            padding: 15px 20px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        }
    }
}

@include screen-medium {
    .width__lg {
        @for $w from 1 through 500 {
            &--#{$w}px {
                width: #{$w}px !important;
            }
        }

        @for $w from 1 through 500 {
            &--#{$w}rem {
                width: #{$w}rem !important;
            }
        }

        @for $w from 1 through 100 {
            &--#{$w} {
                width: #{$w}% !important;
            }
        }
        //&-90{
        //    width: 90% !important;
        //}
    }
}

@include screen-lg {
    .width__lg {
        @for $w from 1 through 500 {
            &--#{$w}px {
                width: #{$w}px !important;
            }
        }

        @for $w from 1 through 500 {
            &--#{$w}rem {
                width: #{$w}rem !important;
            }
        }

        @for $w from 1 through 100 {
            &--#{$w} {
                width: #{$w}% !important;
            }
        }
        //&-90{
        //    width: 90% !important;
        //}
    }
}

@include screen-max-lg {

}
