@import "../variables";

.ant-select-item-option-content {
    color: black
}

.ant-select-selection-item {
    color: black
}

.hn {
    &__embedded {
        &--wrapper {
            .builder-sidebar_search {
                display: none !important;
            }

            .component-settings-button-edit-json {
                display: none !important;
            }

            //.component-settings-button-remove {
            //    display: none !important;
            //}

            .component-settings-button-copy {
                display: none !important;
            }

            .component-settings-button-move {
                display: none !important;
            }

            .form-builder-panel {
                .formcomponent {
                    width: 100%;
                    padding: 8px 15px;
                    background-color: #eaeaea !important;
                    border: 1px solid #cacaca !important;
                    color: #747474;
                    box-shadow: none !important;
                }
            }
        }
    }
}

@include screen-small {
    .hn {
        &__plans-pricing {
            &__packages {
                &--title {
                    .fs--24px {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}

@include screen-medium {

}

@include screen-lg {

}

#hn__embedded-form--model-share-id {
    .ant-modal-body {
        padding-top: 0;
    }
}

.card-header-tabs {
    display: none;
}

.formio-component-labelPosition {
    display: none;
}

.formio-component-prefix {
    display: none;
}

.formio-component-suffix {
    display: none;
}

.formio-component-widget.type {
    display: none;
}

.formio-component-inputMask {
    display: none;
}

.formio-component-displayMask {
    display: none;
}

.formio-component-inputMaskPlaceholderChar {
    display: none;
}

.formio-component-tabindex {
    display: none;
}

.formio-component-checkbox {
    display: none;
}

.formio-component-select {
    display: none;
}

.formio-component-leftIcon {
    display: none;
}

.formio-component-rightIcon {
    display: none;
}
