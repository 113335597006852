@import "../variables";

.hn {
    &__my-subscription {
        &__card {
            div {
                color: black;
            }

            p {
                padding: 0;
                margin-bottom: 5px;
            }

            border-radius: 10px;

            .ant-card-head {
                border-bottom: 0;
            }

            .ant-card-head-wrapper {
                border-bottom: 1px solid $color-gray;
            }

            .ant-card-head-title {
                font-size: 20px;
            }

            &--btn-cancel-renewal {
                //border: 1px solid $color-gray;
                //background-color: #00A1C9;
                background-color: #C4C4C4;
                color: white;

                &-disabled {
                    color: white;
                    background-color: #C4C4C4;

                    &:hover {
                        color: white;
                        //background-color: #C4C4C4;
                    }
                }

                &:hover {
                    color: white;
                    background-color: #00A1C9;
                }
            }

            &--slide {
                border-radius: 10px;
                overflow: hidden;

                .ant-card-body {
                    padding: 0;
                }
            }
        }
    }
}

@include screen-medium {
}

@include screen-lg {
}

@include screen-max-lg {

}
