@import "../variables";

.ant-select-item-option-content {
    color: black
}

.ant-select-selection-item {
    color: black
}

.hn {
    &__contact {
        &--line-table {
            background-color: #F7DC6F !important;
        }

        &--table {
            .ant-table-tbody {
                .ant-table-cell {
                    padding: 0 !important;
                }
            }
        }
    }
}

@include screen-small {
    .hn {
        &__plans-pricing {
            &__packages {
                &--title {
                    .fs--24px {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}

@include screen-medium {

}

@include screen-lg {

}
