$color-primary: #01579F;
$color-danger: #FC304B;
$color-primary-blur: #00B7E3;
$color-foreign: #FCCE01;
$color-gray: #C4C4C4;

$btn-color-primary: $color-primary-blur;
$btn-color-danger: #ff4d4f;

$full-screen: calc(100% - 120px);
$full-screen-vh: calc(100vh - 120px);

@mixin screen-small {
    @media (max-width: 450px) {
        @content;
    }
}

@mixin screen-medium {
    @media (min-width: 450px) and (max-width: 767px) {
        @content;
    }
}

@mixin screen-lg {
    @media (min-width: 767px) {
        @content;
    }
}

@mixin screen-max-lg {
    @media (min-width: 1800px) {
        @content;
    }
}
