@import "../variables";

.hn__workflow {
    &--submit {
        position: absolute;
        right: 40px;
        bottom: 40px;
        z-index: 999;
    }

    &--node-icon {
        position: absolute;
        top: 5px;
        right: 10px;
    }
}
