@import "variables";


body {
    //overflow: hidden;
}

.calling-container {
    position: relative;
    //position: absolute;
    //z-index: 999;
    //top: 0;
    //left: 0;
    //bottom: 0;
    //right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    background-color: #eaeaea;
    color: #fff;
    font-size: 30px;
    padding: 15px;
    line-height: 1;
    border-radius: 50%;
    animation: pulse 1s cubic-bezier(0, 0, 0, 0) 0.3s infinite;
}

.calling-InComing-container {
    position: relative;
    //position: absolute;
    //z-index: 999;
    //top: 0;
    //left: 0;
    //bottom: 0;
    //right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    background-color: #92fd83;
    color: #fff;
    font-size: 30px;
    padding: 15px;
    line-height: 1;
    border-radius: 50%;
    animation: pulseInComing 1s cubic-bezier(0, 0, 0, 0) 0.3s infinite;
}

.calling-icon {
    text-shadow: 0 2px 10px #8e8e8e;
    animation: changeSize 1s cubic-bezier(0, 0, 0, 0) 0s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #acacac;
    }
    25% {
        box-shadow: 0 0 0 10px #cacaca;
    }
    50% {
        box-shadow: 0 0 0 20px #dadada;
    }
    75% {
        box-shadow: 0 0 0 30px #e3e3e3;
    }
    100% {
        box-shadow: 0 0 0 40px #fff;
    }
}

@keyframes pulseInComing {
    0% {
        box-shadow: 0 0 0 0 #c3ffba;
    }
    25% {
        box-shadow: 0 0 0 10px #d7ffd2;
    }
    50% {
        box-shadow: 0 0 0 20px #dfffd9;
    }
    75% {
        box-shadow: 0 0 0 30px #e7ffe3;
    }
    100% {
        box-shadow: 0 0 0 40px #fff;
    }
}

@keyframes changeSize {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(0.8);
    }
    75% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

//.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//    color: $color-primary-blur !important;
//}

//.btn {
//    //border-radius: 5px;
//}
//
//.btn-primary {
//    background-color: $color-primary-blur !important;
//}

//.ant-btn {
//    //border-radius: 5px !important;
//}
//
//.ant-btn-primary {
//    border-color: $color-primary-blur;
//    background: $color-primary-blur;
//}

//.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
//    background-color: white !important;
//    //color: black !important;
//
//    a {
//        color: black;
//
//        &:hover {
//            color: $color-primary-blur !important;
//        }
//    }
//}
//
//.ant-menu-item-selected {
//    a {
//        color: $color-primary-blur !important;
//    }
//}

.hn {
    &__tabs-menu {
        .ant-tabs-tab {
            font-size: 18px;
        }

        .ant-tabs-nav {
            padding: 0 1rem !important;
        }
    }

    &__content {
        //width: 87%;
        //float: left;
        background-color: #ffffff;
        //background-color: #f7f7f7;
        overflow: hidden;
    }

    &__navigation {
        z-index: 10;
        position: fixed;

        .ant-menu.ant-menu-inline-collapsed {
            width: 0px;
        }

        .ant-menu {
            height: 100vh;
            background-color: white !important;
            -webkit-box-shadow: 15px 6px 38px -11px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 15px 6px 38px -11px rgba(0, 0, 0, 0.2) !important;
        }

        .ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-submenu-title {
            color: black !important;
        }

        .ant-menu-submenu-arrow {
            color: black !important;
        }

        .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
            background-color: black !important;
        }

        .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
            background-color: black !important;
        }

        &--mobile {
            .ant-menu {
                height: 100vh;
                background-color: white;
            }

            .ant-drawer-header {
                background-color: #81A540;
                padding-top: 0;
                padding-bottom: 0;
            }

            .ant-drawer-body {
                padding: 0;
            }

            .ant-drawer-close {
                //color: white;
            }

            .ant-menu-title-content {
                color: black;
            }

            .ant-menu-submenu-arrow {
                &:after {
                    background: black !important;
                }

                &:before {
                    background: black !important;
                }
            }

            .ant-menu-item-selected {
                background-color: white !important;
                color: $color-primary-blur !important;
            }
        }
    }

    &__footer {
        height: 50px;
        padding: 10px 25px;
        background-color: white;
        //border-top: 1px solid #c1c1c1;
        box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.75);
    }
}

.hn-page-builders {
    &__navigation {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        //margin-left: 45%;
        margin: auto;
        z-index: 1000;
        width: 900px;
    }
}

@include screen-small {

}

@include screen-medium {

}

@include screen-lg {
    .ant-scrolling-effect {
        overflow: unset !important;
    }
    * {
        //p {
        //    font-size: 18px !important;
        //}
        //
        //a {
        //    font-size: 18px !important;
        //}
        //
        //label {
        //    font-size: 18px !important;
        //}

        //.ant-popover-buttons {
        //    .ant-btn {
        //        span {
        //            font-size: 14px !important;
        //        }
        //    }
        //}
        //
        //.ant-btn {
        //    padding: 0 15px !important;
        //
        //    span {
        //        font-size: 18px !important;
        //    }
        //}
    }
    .ant-menu-vertical {
        background-color: #123963 !important;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: unset !important;
    }
    .ant-menu-submenu-popup {
        //left: 230px !important;
    }
    .custom-table {
        //.ant-table-cell {
        //    padding: 0 16px;
        //    font-size: 14px;
        //}
    }
    .hn {
        &__content {
            //height: 100vh;
            transition-property: margin-left;
            transition-timing-function: cubic-bezier(.075, .82, .165, 1);
            transition-duration: .05s;
            //margin-left: 230px;
            //margin-top: 72px;

            &--expanded {
                margin-left: 0px;
            }
        }

        &__navigation {

            &--mobile {
                display: none;
            }
        }
    }
}

@include screen-max-lg {
    .max {
        &__width {
            &--400px {
                width: 400px !important;
            }
        }
    }
}

/**
 * ==============================================
 * Dot Carousel
 * ==============================================
 */
.dot-carousel {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    animation: dot-carousel 1.5s infinite linear;
}

@keyframes dot-carousel {
    0% {
        box-shadow: 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff;
    }
    50% {
        box-shadow: 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff;
    }
    100% {
        box-shadow: 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff;
    }
}

.incomming-voice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.incomming-voice-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.incomming-voice-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.volume-control {
    display: flex;
    align-items: center;
}

.volume-icon {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    z-index: 1;
}

.volume-bar {
    width: 70%;
    height: 10px;
    margin: auto;
    background-color: #e2e8f0;
    position: relative;
}

.volume-level {
    height: 100%;
    transition: width 0.3s ease-in-out;
}

