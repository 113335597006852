$colorNode: #af9ede;
.EdgeAddButton {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        border-color: $colorNode;
        border-width: 2px;
        color: $colorNode;
    }
}
