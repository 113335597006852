@import "variables";

.hdr-nav-bar .navbar .navbar-nav > li a {
    border-left: 0 !important;
}

.hn {
    &__header {
        &--notify {
            position: absolute;
            top: 0;
            right: 0;
            width: 400px;
            height: 400px;
            overflow-y: scroll;
            background-color: white;
            z-index: 999999;
            margin-top: 35px;
            margin-right: 10px;

            &-item {
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                }
            }
        }

        .ant-avatar-string {
            padding-top: 5px;
        }

        &--menu {
            background-color: transparent;
            border: 0;
            line-height: 70px !important;

            .ant-menu-submenu-title {
                .ant-menu-title-content {
                    color: white
                }
            }

            .ant-menu-item-selected {
                background-color: rgba(0, 0, 0, 0.2);

                a {
                    color: white !important;
                }

                &:after {
                    border-bottom: 0 !important;
                }
            }

            .ant-menu-item {
                a {
                    &:hover {
                        color: black !important;
                    }
                }

                &:after {
                    border-bottom: 0 !important;
                }
            }

            .ant-menu-title-content {
                a {
                    color: white;
                }
            }
        }

        //overflow: hidden;
        ///* ff 3.6+ */
        //background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        //
        ///* safari 5.1+,chrome 10+ */
        //background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        //
        ///* opera 11.10+ */
        //background: -o-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        //
        ///* ie 6-9 */
        //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#123963', endColorstr='#FFFFFF', GradientType=0);
        //
        ///* ie 10+ */
        //background: -ms-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        //
        ///* global 94%+ browsers support */
        //background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        background-color: #81A540;
        color: #b2b2b2;
        //height: 72px;
        text-align: center;
        width: 100%;
        z-index: 99;
        position: sticky;
        top: 0;
        -webkit-box-shadow: -50px 10px 26px -8px rgba(0, 0, 0, 0.3);
        box-shadow: -50px 10px 26px -8px rgba(0, 0, 0, 0.3);

        &--logo {
            //width: 155px;
        }

        &--action-page {

            /*******************
             Horizontal-navabar
            *******************/
            .hdr-nav-bar .navbar {
                background: #ffffff;
                border-top: 1px solid rgba(120, 130, 140, 0.13);
                padding: 0 15px;
            }

            .hdr-nav-bar .navbar .navbar-nav > li {
                padding: 0 10px;
            }

            .hdr-nav-bar .navbar .navbar-nav > li > a {
                padding: 10px 5px;
                padding-top: 20px;
                transition: 0.2s ease-in;
                border-bottom: 2px solid transparent;
            }

            .hdr-nav-bar .navbar .navbar-nav > li.active > a,
            .hdr-nav-bar .navbar .navbar-nav > li:hover > a,
            .hdr-nav-bar .navbar .navbar-nav > li > a:focus {
                border-color: #398bf7;
            }

            .hdr-nav-bar .navbar .navbar-nav .dropdown-toggle:after {
                display: none;
            }

            .hdr-nav-bar .navbar .navbar-brand {
                padding: 20px 5px;
            }

            .hdr-nav-bar .navbar .navbar-toggler {
                border: 0px;
                cursor: pointer;
            }

            .hdr-nav-bar .navbar .custom-select {
                border-radius: 60px;
                font-size: 14px;
                padding: .375rem 40px .375rem 18px;
            }

            .hdr-nav-bar .navbar .call-to-act {
                padding: 15px 0;
            }

            .headerTitle {
                color: #01a0c6;
                margin-bottom: 5px;
            }
        }
    }
}

@include screen-small {
    .hn {
        &__header {
            &--logo {
                img {
                    width: 145px;
                }
            }

            &--toggle {
                font-size: 30px;
            }
        }
    }
}

@include screen-medium {

}

@include screen-lg {

}

@include screen-max-lg {

}
