@import "../variables";

.App {
    text-align: center;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1.5vmin);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.characters {
    list-style: none;
    padding-left: 0;
}

.characters li {
    display: flex;
    align-items: center;
    border: solid 2px #d0d0d0;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    margin-bottom: 1em;
}

.characters p {
    max-width: none;
    font-weight: bold;
    margin: 0;
}

.characters-thumb {
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    background-color: #e8e8e8;
    padding: .5em;
    margin-right: .5em;
}

.characters-thumb img {
    display: block;
    width: 100%;
    height: auto;
}


.task {
    &__container {
        &--wrapper {
            padding: 20px;
            //border: 4px solid indianred;
            border-radius: 6px;
        }

        &--list-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 8px;
        }
    }

    &__item {
        &--avatar {
            height: 40px;
            width: 40px;
            border: 3px solid white;
            border-radius: 50%;
        }

        &--card-header {
            font-weight: 500;
        }

        &--author {
            //display: flex;
            align-items: center;
        }

        &--card-footer {
            width: 100%;
            //display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--drag-item {
            padding: 10px;
            border-radius: 6px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            background: white;
            margin: 0 0 8px 0;
            display: grid;
            grid-gap: 5px;
            flex-direction: column;
        }
    }

    &__list {
        &--wrapper {
            padding: 10px;
            border-radius: 6px;
            background: #efefef;
        }

        &--column-header {
            text-transform: uppercase;
            margin-bottom: 20px;
            font-weight: bold;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

@include screen-small {
}

@include screen-medium {

}

@include screen-lg {

}
